import React, { useState } from 'react'
import man from '../assets/images/choose-img.svg'
import meeting from '../assets/images/meeting.png'
import chip from '../assets/images/chip.png'
import like from '../assets/images/like.png'
import best from '../assets/images/best.png'
import commitment from '../assets/images/commitment.png'

function Accordion({classes}) {
    const [index, setIndex] = useState(1)

    return (
        <section className={`acc-cont ${classes || ''}`}>
            <div className="container mx-auto px-4 py-16">
            <div className="text-center mb-8">
                <p className="text-[#4B91AB] text-lg md:text-xl font-heading">Our Services</p>
                <h1 className="text-[#2E5969] font-semibold text-2xl md:text-xl md:max-w-xl mx-auto mb-5 ">As a trading company, we offer a wide range of services 
                designed to meet the needs of our customers. Our services include:.</h1>
                {/* <p className="text-gray-700 max-w-3xl mx-auto font-heading">We make fast loading, friction removing, problem-solving products that get used by millions of people each month!</p> */}
            </div>
            <div className="container mx-auto px-4 flex flex-col md:flex-row py-8 justify-evenly items-start">
                <img src={man} alt="" className="md:w-2/5 select-none rounded-2xl" />
                <ul className="list-none mt-14 md:mt-0 md:w-2/5">
                <li onClick={() => indexHandler(1)}
                        className={`${(index === 1) ? 'shadow-md' : 'shadow-lg'} mb-8 rounded-lg border border-gray-200 cursor-pointer`}>
                        <div className={`${(index === 1) ? 'bg-[#274C59] text-white' : 'bg-white text-indigo-950'}
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}>
                            <div className="flex items-center">
                                <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                                    <img src={commitment} alt="" className="w-7 h-7" />
                                </div>
                                <h2 className="font-medium text-lg font-heading">
                                  Unmatched Product Sourcing
                                </h2>
                            </div>
                            <div className={`${(index === 1) ? 'text-white rotate-135' : 'text-black rotate-0'} select-none text-2xl transition-all`}>+</div>
                        </div>
                        <div className={`${(index === 1) ? 'max-h-48 py-4' : 'max-h-0 py-0'} bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}>
                      Our team of experts sources only the finest medical products from around the world, including premium medical device, top-notch sutures, innovative orthopedic implants,
                      indispensable disposable medical devices, and cutting-edge diagnostic medical equipment.
                        </div>
                    </li>
                    <li onClick={() => indexHandler(2)}
                        className={`${(index === 2) ? 'shadow-md' : 'shadow-lg'} mb-8 rounded-lg border border-gray-200 cursor-pointer`}>
                        <div className={`${(index === 2) ? 'bg-[#274C59] text-white' : 'bg-white text-indigo-950'}
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}>
                            <div className="flex items-center">
                                <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                                    <img src={meeting} alt="" className="w-7 h-7" />
                                </div>
                                <h2 className="font-medium text-lg font-heading">
                                 In-Depth Market Research 
                                </h2>
                            </div>
                            <div className={`${(index === 2) ? 'text-white rotate-135' : 'text-black rotate-0'} select-none text-2xl transition-all`}>+</div>
                        </div>
                        <div className={`${(index === 2) ? 'max-h-48 py-4' : 'max-h-0 py-0'} bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}>
                        Our team of experts conducts market research to identify trends and opportunities in the healthcare industry, so you can stay ahead of the curve.
                        </div>
                    </li>
                    <li onClick={() => indexHandler(3)}
                        className={`${(index === 3) ? 'shadow-md' : 'shadow-lg'} mb-8 rounded-lg border border-gray-200 cursor-pointer`}>
                        <div className={`${(index === 3) ? 'bg-[#274C59] text-white' : 'bg-white text-indigo-950'}
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}>
                            <div className="flex items-center">
                                <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                                    <img src={chip} alt="" className="w-7 h-7" />
                                </div>
                                <h2 className="font-medium text-lg font-heading">
                                   Lightning-Fast Shipping 
                                </h2>
                            </div>
                            <div className={`${(index === 3) ? 'text-white rotate-135' : 'text-black rotate-0'} select-none text-2xl transition-all`}>+</div>
                        </div>
                        <div className={`${(index === 3) ? 'max-h-48 py-4' : 'max-h-0 py-0'} bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}>
                           We understand that time is of the essence, and we work hard to ensure that your orders are shipped quickly and efficiently, so you can receive the products
                           you need as soon as possible.
                        </div>
                    </li>
                    <li onClick={() => indexHandler(4)}
                        className={`${(index === 4) ? 'shadow-md' : 'shadow-lg'} mb-8 rounded-lg border border-gray-200 cursor-pointer`}>
                        <div className={`${(index === 4) ? 'bg-[#274C59] text-white' : 'bg-white text-indigo-950'}
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}>
                            <div className="flex items-center">
                                <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                                    <img src={like} alt="" className="w-7 h-7" />
                                </div>
                                <h2 className="font-medium text-lg font-heading">
                                 Unbeatable Value
                                </h2>
                            </div>
                            <div className={`${(index === 4) ? 'text-white rotate-135' : 'text-black rotate-0'} select-none text-2xl transition-all`}>+</div>
                        </div>
                        <div className={`${(index === 4) ? 'max-h-48 py-4' : 'max-h-0 py-0'} bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}>
                          We believe in providing our customers with the best possible value, and we offer competitive prices on all of our products.
                        </div>
                    </li>
                    <li onClick={() => indexHandler(5)}
                        className={`${(index === 5) ? 'shadow-md' : 'shadow-lg'} mb-8 rounded-lg border border-gray-200 cursor-pointer`}>
                        <div className={`${(index === 5) ? 'bg-[#274C59] text-white' : 'bg-white text-indigo-950'}
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}>
                            <div className="flex items-center">
                                <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                                    <img src={best} alt="" className="w-7 h-7" />
                                </div>
                                <h2 className="font-medium text-lg font-heading">
                                 Market Access
                                </h2>
                            </div>
                            <div className={`${(index === 5) ? 'text-white rotate-135' : 'text-black rotate-0'} select-none text-2xl transition-all`}>+</div>
                        </div>
                        <div className={`${(index === 5) ? 'max-h-48 py-4' : 'max-h-0 py-0'} bg-white font-body overflow-hidden px-4 
                            transition-all duration-500 text-gray-800`}>
                                We have extensive experience in navigating the complex market landscape of the healthcare industry, and we work closely with our customers to help them 
                                gain access to new markets.
                        </div>
                    </li>
                </ul>
            </div>
            </div>
        </section>
    )

    function indexHandler(i) {
        const j = ((index && i) === index) ? -1 : i
        setIndex(j)
    }
}

export default Accordion
