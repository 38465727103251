import React, { useEffect, useState, useRef } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import world from '../assets/images/world.svg'
import { Web } from '../components'
import dots from '../assets/images/dots.png'
import ximg from '../assets/images/x.png'
import timg from '../assets/images/t.png'
import oimg from '../assets/images/o.png'
import rimg from '../assets/images/r.png'
import emailjs from '@emailjs/browser';

function ContactPage() {
    const form = useRef();
    const [errors, setErrors] = useState({})
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [response, setResponse] = useState('')
    const position = [11.588862, 43.145452]   



    const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_USER_ID } = process.env;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="">
            <div className="relative bg-[#f5f3f3] py-40 overflow-hidden">
                <Web />
                <div className="container relative z-10 mx-auto px-4">
                    <div className="text-white text-center mb-8">
                        <h1 className="text-[#4B91AB] font-bold font-heading text-4xl md:text-4xl mt-3 mb-5">
                            Contact Us  
                        </h1>
                        <p className="text-gray-600 max-w-2xl md:text-lg font-medium font-body mx-auto">
                           Get in Touch with Us for Quality Healthcare Machines. Our team is dedicated 
                           to providing exceptional service and support. Contact us today to learn how we can meet your needs.
                        </p>
                    </div>
                </div>
                <div className="absolute bottom-1/5  left-1/4 animate-grow z-0">
                    <img src={dots} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute top-12 left-1/2 animate-spin-slow z-0">
                    <img src={timg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute bottom-0 right-1/3 animate-spin animate-movearound z-0">
                    <img src={ximg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute bottom-1/3 left-10 animate-spin-slow z-0">
                    <img src={rimg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute bottom-20 left-1/3 animate-movearound z-0">
                    <img src={oimg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute top-1/2 right-20 animate-spin-slow z-0">
                    <img src={timg} alt="" className="max-w-full h-auto" />
                </div>
                <img src={world} alt="" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-250 opacity-10 w-4/6 md:w-2/5 max-h-96" />
            </div>

            <div className="container mx-auto px-4 flex flex-col md:flex-row py-16 justify-evenly items-center">
                <form ref={form} onSubmit={onSubmit} className="md:w-1/3">
                    <div className="mt-3">
                        <span className="font-body after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-gray-600">
                            Your Name
                        </span>
                        <input name="Name" type="text" className={`mt-1 block w-full px-3 py-2 bg-white border rounded-md text-lg shadow-sm placeholder-gray-400
                            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                            ${submitted ? 'disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none' : ""}
                            ${errors['fullName'] ? 'border-pink-500 focus:border-pink-500 focus:ring-pink-500' : 'border-gray-300'}`}
                            placeholder="Your full name" disabled={submitted}
                            value={fullName} onChange={(e) => setFullName(e.target.value)} />
                        <p className={`${errors['fullName'] ? 'visible' : 'invisible'} mt-1 text-pink-600 text-sm`}>
                            Name is a required field
                        </p>
                    </div>

                    <div className="mt-1">
                        <span className="font-body after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-gray-600">
                            Email
                        </span>
                        <input name="user_email" type="email" className={`mt-1 block w-full px-3 py-2 bg-white border rounded-md text-lg shadow-sm placeholder-gray-400
                            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                            ${submitted ? 'disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none' : ""}
                            ${errors['email'] ? 'border-pink-500 focus:border-pink-500 focus:ring-pink-500' : 'border-gray-300'}`}
                            placeholder="Your e-mail address" disabled={submitted}
                            value={email} onChange={(e) => setEmail(e.target.value)} />
                        <p className={`${errors['email'] ? 'visible' : 'invisible'} mt-1 text-pink-600 text-sm`}>
                            Please, provide a valid e-mail address
                        </p>
                    </div>
                    <div className="mt-1">
                        <span className="font-body after:content-['*'] after:ml-0.5 after:text-red-500 block text-base font-medium text-gray-600">
                            Message
                        </span>
                        <textarea name="message" cols="30" rows="4" className={`mt-1 block w-full px-3 py-2 bg-white border rounded-md text-lg shadow-sm placeholder-gray-400
                            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                            ${submitted ? 'disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none' : ""}
                            ${errors['message'] ? 'border-pink-500 focus:border-pink-500 focus:ring-pink-500' : 'border-gray-300'}`}
                            placeholder="Your Message" disabled={submitted}
                            value={message} onChange={(e) => setMessage(e.target.value)} />
                        <p className={`${errors['message'] ? 'visible' : 'invisible'} mt-1 text-pink-600 text-sm`}>
                            Message is a required field
                        </p>
                    </div>
                    <div className="flex items-center">
                        <button className="font-body text-lg bg-[#1782AA] hover:bg-[#68C9EC] px-5 py-2.5 leading-5 
                            rounded-md font-semibold text-white" disabled={submitted}>
                            Submit
                        </button>
                        <p className={`font-heading ${response?.includes('Error') ? 'text-red-500' : 'text-green-500'} font-semibold text-lg ml-3`}>{response}</p>
                    </div>
                </form>

                <img src={world} alt="" className="mt-12 md:mt-0 md:my-0 w-4/5 md:w-2/5" />

            </div>

            {/* <div className="container mx-auto px-4 flex flex-col md:flex-row py-8 justify-evenly items-center">
                <div ref={mapContainer} className='h-96 w-full relative' />
                <MapContainer center={position} zoom={8} scrollWheelZoom={true} attributionControl={false} className="h-96 w-full relative focus:outline-none">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position}>
                        <Popup>
                        CITE Wadajir Djibouti
                        </Popup>
                    </Marker>
                </MapContainer>
            </div> */}
        </div>
    )

    function onSubmit(e) {
        e.preventDefault()
        setResponse('')
        if (!handleValidation()) return;
        setSubmitted(true)

        emailjs.sendForm(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, form.current, REACT_APP_USER_ID)
            .then(function () {
                console.log('SUCCESS!');
                setResponse('Successfully submitted!')
                setSubmitted(false);
            }, function (error) {
                console.log('FAILED...', error);
                setResponse('Error while submitting!')
                setSubmitted(false);
            });
    }

    function handleValidation() {
        let fields = { fullName, email, message };
        let errs = {};
        let formIsValid = true;

        // First Name
        if (!fields["fullName"]) {
            formIsValid = false;
            errs["fullName"] = "First name cannot be empty";
        }
        // Email
        if (!fields["email"]) {
            formIsValid = false;
            errs["email"] = "E-mail cannot be empty";
            const isvalid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
            if (!isvalid) {
                formIsValid = false;
                errs["email"] = "Please enter a valid e-mail address"
            }
        }

        // Message
        if (!fields["message"]) {
            formIsValid = false;
            errs["message"] = "Message cannot be empty";
        }

        setErrors(errs)
        return formIsValid;
    }
}

export default ContactPage
