import React from 'react'
import logo from '../assets/images/logo-3.png'
import cloud from '../assets/images/hover.png'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className="relative w-full px-20 py-10 bg-[#62a0b9]">
            <div className="container mx-auto flex flex-col md:flex-row justify-between text-white">
                <div className="sr-only md:not-sr-only flex flex-col grow">
                    <div className="flex mb-5 items-end select-none">
                    <p className="uppercase font-bold text-white text-2xl">Lean General Trading</p>
                        {/* <img src={logoText} alt="" className="ml-2 w-36" /> */}
                    </div>
                    <p className="max-w-sm text-sm text-gray-50 font-body">
                    Lean General Trading Company is dedicated to providing high-quality pharmaceutical products and 
                        services to meet the needs of our clients. Our experienced and knowledgeable staff is here to answer
                        any questions and help you find the right products to fit your needs.
                    </p>
                    <p className="my-4 text-gray-300/90">
                      Based in <span className="text-white">Djibouti City</span>
                    </p>
                    <div className="mt-1">
                        {/* <img src={fb} alt="" className="w-7 cursor-pointer" />
                        <img src={twt} alt="" className="w-7 mx-4 cursor-pointer" />
                        <img src={insta} alt="" className="w-7 cursor-pointer" /> */}
                        <Link to="/privacy" className="font-heading font-semibold text-slate-100 hover:text-gray-400 transition-colors cursor-pointer text-center md:text-left w-full">Privacy Notice</Link>
                    </div>
                </div>
                <div className="flex flex-col grow items-center md:items-start">
                    <h1 className="flex font-semibold font-heading mb-4 text-2xl">
                        <div className="relative w-1 mr-3 bg-pink-600">
                            <div className="absolute -translate-y-1/2 bg-indigo-950/95 animate-bounce-big w-full h-2"></div>
                        </div>
                        Contacts
                    </h1>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">Phone: +253 775 126 91</span>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">Email: leangeneraltrading@gmail.com</span>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">Headquarter: Cite Wadajir, Free Zone Logistics Center</span>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">Djibouti City</span>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">Djibouti </span>
                </div>
                <div className="flex flex-col grow items-center md:items-start">
                    <h1 className="flex font-semibold font-heading mt-5 md:mt-0 mb-4 text-2xl">
                        <div className="relative w-1 mr-3 bg-pink-600">
                            <div className="absolute -translate-y-1/2 bg-indigo-950/95 animate-bounce-big w-full h-2"></div>
                        </div>
                    Our Products
                    </h1>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">ACCRE Machines</span>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">Finecare Machines</span>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">ACCRE reagents</span>
                    <span className="my-1.5 font-body text-gray-50 transition-colors cursor-default text-center md:text-left w-full">Finecare reagents</span>
                </div>
                <div className="md:sr-only flex flex-col items-center grow-2">
                    <div className="my-6 select-none"> 
                        {/* <img src={logo} alt="" className="w-52" /> */}
                    </div>
                    {/* <div className="flex justify-center">
                        <img src={fb} alt="" className="w-9 cursor-pointer" />
                        <img src={twt} alt="" className="w-9 mx-10 cursor-pointer" />
                        <img src={insta} alt="" className="w-9 cursor-pointer" />
                    </div> */}
                    {/* <div className="pt-4">
                    <Link to="/privacy" className="mx-4 font-heading text-gray-300 hover:text-gray-400 transition-colors cursor-default text-center md:text-left w-full">Privacy Notice</Link>
                    </div> */}
                </div>
            </div>
            <img src={cloud} alt="" className="absolute h-full top-0 right-0 select-none" />
        </div>
    )
}

export default Footer;
