import React, { useLayoutEffect } from 'react'
import img from '../assets/images/home-bg.png'
import dots from '../assets/images/dots.png'
import ximg from '../assets/images/x.png'
import timg from '../assets/images/t.png'
import oimg from '../assets/images/o.png'
import rimg from '../assets/images/r.png'
import Web from './Web'


import { Link } from 'react-router-dom'

const classes = ['animate-appearup', 'animate-appearup-2', 'animate-moveleft', 'animate-appearup']

function MainBody() {

    useLayoutEffect(() => {
        const cards = document.querySelectorAll('.main-header')
        const img = document.querySelector('.main-img')
        const observer = new IntersectionObserver(entries => {
            entries.forEach((ele, i) => {
                // console.log(i,ele.target)
                ele.target.classList.add(classes[i])
            })
        })
        cards.forEach(card => {
            observer.observe(card)
        })
        observer.observe(img)
        return () => {
            cards.forEach(card => {
                observer.disconnect(card)
            })
        }
    }, [])

    return (
     <section className="relative bg-main bg-cover min-h-screen pb-20 md:pb-0 flex justify-center items-center overflow-hidden">
            <Web />
            <div className="relative z-10 md:px-20 mt-32 md:mt-0">
                <div className="absolute top-10 left-1/2  animate-grow -z-10">
                    <img src={dots} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute top-0 left-1/2 animate-spin animate-movearound -z-10">
                    <img src={ximg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute bottom-0 animate-spin-slow -z-10">
                    <img src={rimg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute bottom-0 right-1/2 animate-movearound -z-10">
                    <img src={oimg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute top-1/2 right-0 animate-spin animate-movearound -z-10">
                    <img src={timg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="container mx-auto lg:mt-32 flex flex-col md:flex-row items-center">
                    <div className="flex-1 text-white px-6 md:px-0">
                        <h1 className="main-header opacity-0 font-bold font-heading text-3xl text-center md:text-left md:text-5xl mb-6">
                            Trusted Effective Trading Service
                           {/* Elevating health and wellness to new heights */}
                        </h1>
                        <p className="main-header opacity-0 font-light font-body text-lg text-center md:text-left">
                              Welcome to Lean General Trading SARL, your one-stop-shop embrace the power of quality products.
                              We are a leading lab machine import company based in Djibouti dedicated to bringing the
                               latest and best products to our customers.
                        </p>
                        <div className='flex'>
                        <div className="flex flex-wrap main-header opacity-0 mt-14 mb-8 text-center md:text-left">
                            <Link to="/contact" className="mr-6 py-4 px-7 rounded-md bg-[#1782AA] text-white hover:bg-[#68C9EC] hover:text-[#2E5969] whitespace-nowrap transition-colors">Contact Us</Link>
                        </div>
                         <div className="flex flex-wrap main-header opacity-0 mt-14 mb-8 text-center md:text-left">
                            <Link to="/portfolio" className="mr-6 py-4 px-7 rounded-md bg-[#1782AA] text-white hover:bg-[#68C9EC] hover:text-[#2E5969] whitespace-nowrap transition-colors">See Products</Link>
                        </div>
                        </div>
                    </div>
                    <div className="mx-6 md:mx-0 mt-6 md:mt-0 lg:-mt-28 flex-1 main-img opacity-0">
                        <img src={img} alt="" className="animate-grow max-w-full h-auto" />
                    </div>
                </div>
            </div>
        </section>  
    )
}

export default MainBody
