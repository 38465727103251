import React, { useEffect } from 'react'
import { Web, WorkGroup } from '../components'
import dots from '../assets/images/dots.png'
import ximg from '../assets/images/x.png'
import timg from '../assets/images/t.png'
import oimg from '../assets/images/o.png'
import rimg from '../assets/images/r.png'
import tabs from '../assets/images/tabs.svg'

function PortfolioPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="">
            <div className="relative bg-[#f5f3f3] py-40 overflow-hidden">
                <Web />
                <div className="container relative z-10 mx-auto px-4">
                    <div className="text-white text-center mb-8">
                        <h1 className="font-bold text-[#4B91AB] font-heading text-4xl md:text-4xl mt-3 mb-5 capitalize">
                          Product Lines 
                        </h1>
                        <p className=" text-gray-600 max-w-2xl md:text-lg font-medium font-body mx-auto">
                         Our trading import company offers a range of product lines, including medical lab devices, and corresponding agents.
                         We strive to provide high-quality products to meet the needs of healthcare professionals and their patients.
                        </p>
                    </div>
                </div>
                <div className="absolute bottom-1/5 left-1/4 animate-grow z-0">
                  <img src={dots} alt="" className="max-w-full h-auto filter-grayscale-500" />
                </div>
                <div className="absolute top-12 left-1/2 animate-spin-slow z-0">
                    <img src={timg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute bottom-0 right-1/3 animate-spin animate-movearound z-0">
                    <img src={ximg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute bottom-1/3 left-10 animate-spin-slow z-0">
                    <img src={rimg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute bottom-20 left-1/3 animate-movearound z-0">
                    <img src={oimg} alt="" className="max-w-full h-auto" />
                </div>
                <div className="absolute top-1/2 right-20 animate-spin-slow z-0">
                    <img src={timg} alt="" className="max-w-full h-auto" />
                </div>
                <img src={tabs} alt="" className="absolute top-1/2 left-1/2 scale-250 -translate-x-1/2 -translate-y-1/2 opacity-10 w-4/6 md:w-2/5 max-h-96" />
            </div>

            <WorkGroup />

        </div>
    )
}

export default PortfolioPage
