import React, { useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { Navbar, Footer } from './components'
import { AboutPage, ContactPage, LandingPage, NotFoundPage, PortfolioPage, PrivacyPage, DetailsPage } from './pages'
import { useCookies } from 'react-cookie';
// import { useRive } from 'rive-react'
import './index.css';

function App() {
  const [cookies, setCookie] = useCookies(['cookie']);
  const [loading, ] = useState(false)
    const [currentRoute, setCurrentRoute] = useState(window.location.pathname);

  // const { RiveComponent } = useRive({
  //   src: './merebpreloaderanimation.riv',
  //   autoplay: true,
  //   onStop: () => setLoading(false)
  // });
  
  function onAccept() {
    setCookie('cookie', 'cookie', { path: '/' });
  }
  if(loading && currentRoute === '/')  {
    return (
      <div style={{background: "#577794"}} className="min-h-screen flex justify-center items-center">
        {/* <RiveComponent width={90} height={90} /> */}
      </div>
    )
  }
  function handleRouteChange(route) {
  setCurrentRoute(route);
}

  return (
    <div>
      <Navbar />
      <div className="min-h-screen">
        <Routes>
          <Route onChange={handleRouteChange} path="/" element={<LandingPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          {/* <Route path="portfolio/*" element={<DetailsPage />} /> */}
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="author/Lean General Trading" element={<Navigate replace to="/" />} />       
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      {!cookies.cookie && <div className="animate-cookie">
        <div className="CookieConsent">
          <div className="customCookie container mx-auto flex flex-col justify-center items-center py-4 px-4 md:flex-row">
            <div>
              <p className="px-4">
              Lean General Trading uses cookies to provide enhanced website functionality and experience. By using our website, you agree to<a className="mx-2 text-gray-700 hover:text-gray-400 transition-colors cursor-default text-center md:text-left w-full" href="/privacy">our privacy notice.</a>
              </p>
            </div>
            <div>
              <button id="rcc-confirm-button" aria-label="Accept cookies" onClick={onAccept}>
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
      }
      <Footer />
    </div>
  )
}

export default App
