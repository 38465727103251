import React, { useLayoutEffect } from 'react'
import { Work } from '.'
import arCoke from '../assets/projects/coca.jpg'
import fifa from '../assets/projects/fifa.png'
import nunet from '../assets/projects/nunet.png'
import block from '../assets/projects/block.png'
import analysis from '../assets/projects/analysis.png'
import hormn from '../assets/projects/hormn.png'
import vin from '../assets/projects/vin.jpg'
import electro from '../assets/projects/electro.jpg'
import chem from '../assets/projects/chem.jpg'




const cardContent = [
    {
        imageTop: vin,
        title: "Accre 90—Automatic Chemiluminescence",
        imageIcons: [
          
        ],
        category: ["Analyzer", "Medical Device"],
    },
     {
        imageTop: hormn,
        title: "Finecare™ FIA Meter",
        imageIcons: [
          
        ],
        category: ["Analyzer", "Medical Device"],
    }

];

function WorkGroup() {
    useLayoutEffect(() => {
        const cards = document.querySelectorAll('.work')
        const observer = new IntersectionObserver(entries => {
            entries.forEach(ele => {
                if (ele.isIntersecting) {
                    ele.target.classList.add('animate-moveup')
                }
            })
        })
        cards.forEach(card => {
            observer.observe(card)
        })
        return () => {
            cards.forEach(card => {
                observer.disconnect(card)
            })
        }
    }, [])
    const contents = ['1', '2']

    return (
        <div className="md:px-20">
            <div className="container mx-auto px-2 md:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 py-16 lg:gap-8 lg:flex-wrap">
                {contents.map((x, i) => <Work key={i} index={i + 1} content={cardContent[i]} />)}
            </div>
        </div>
    )
}

export default WorkGroup
