import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import ceoimg from '../assets/images/office/office-7.jpg'
import check from '../assets/images/check.png'

function Ceo() {
    useLayoutEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(ele => {
                if (ele.isIntersecting) {
                    ele.target.classList.add('animate-moveleft')
                }
            })
        })
        const container = document.querySelector('.img-container');
        observer.observe(container)
        return () => {
            observer.disconnect(container)
        }
    }, [])

    return (
        <section className="bg-[#f5f3f3] xl:px-20">
            <div className="flex flex-col xl:flex-row justify-between items-center container mx-auto py-12 lg:py-16">
                <div className="max-w-2xl my-12 px-4 lg:px-0 xl:my-0 font-heading">
                    {/* <p className="text-blue-700 text-lg lg:text-xl font-heading">Features</p> */}
                    <h1 className="text-[#4B91AB] font-semibold text-2xl lg:text-4xl lg:max-w-xl mt-2 mb-5 capitalize">
                        Our Mission 
                    </h1>
                    <p className="text-gray-600 leading-7 text-lg">
                       At Lean General Trading Company, we are dedicated to improving the distribution of healthcare products to individuals and
                       communities. Our mission is to provide access to the highest quality medical products and services, at 
                       competitive prices, with a focus on sustainability and customer satisfaction.With a commitment to affordability, 
                       sustainability, and ethical business practices, Lean General Trading Company is dedicated to improving the healthcare material distribution.
                    </p>
                    <div className="grid gap-x-12 md:grid-cols-1 lg:grid-cols-2 grid-rows-3 mt-5">
                        <p className="mb-3 text-xl text-gray-600  flex items-center">
                            <img src={check} alt="" className="w-5 h-5 mr-3" />
                          Quality Medical Product Sourcing
                        </p>
                        <p className="mb-3 text-xl text-gray-600  flex items-center">
                            <img src={check} alt="" className="w-5 h-5 mr-3" />
                           Affordable Medical Imports
                        </p>
                        <p className="mb-3 text-xl text-gray-600  flex items-center">
                            <img src={check} alt="" className="w-5 h-5 mr-3" />
                           Dedicated Healthcare Partner
                        </p>
                        <p className="mb-3 text-xl text-gray-600 flex items-center">
                            <img src={check} alt="" className="w-5 h-5 mr-3" />
                           Competitive Pricing
                        </p>
                        <p className="mb-3 text-xl text-gray-600  flex items-center">
                            <img src={check} alt="" className="w-5 h-5 mr-3" />
                           Accessible Medical Products 
                        </p>
                    </div>
                    <Link to="/contact" className="inline-block bg-[#20B3E8] cursor-pointer transition-colors hover:bg-[#1782AA]
                            font-medium text-white mt-3 px-6 py-3 rounded">Let's Talk!</Link>
                </div>
                <div className="mx-6 md:mx-0 select-none img-container relative opacity-0">
                    <img src={ceoimg} alt="" className="relative z-10 curved-img h-60 sm:h-80 lg:h-96 transition-transform" />
                
                </div>
            </div>
        </section>
    )
}

// {/* <div className="max-w-2xl text-center mt-10 px-4 lg:px-0 lg:mt-0 lg:text-left">
//     <h1 className="uppercase text-indigo-900 text-2xl lg:text-3xl font-bold font-heading">Software development outsourcing</h1>
//     {/* <h2 className="my-3 text-lg lg:text-xl font-heading">Take on the software challenges and develop premium solutions with a robust technical partner.</h2> */}
//     <p className="my-3 text-gray-700 lg:text-base font-body">
//         Take on the software challenges and develop premium solutions with a robust technical partner.
//         We provide comprehensive solutions in software outsourcing: from support in product development to general digital
//         transformation, testing, and maintenance.
//         All services are adapted to the specific needs and tasks of our partners so that the end result can be achieved promptly within timelines and budget.
//     </p>
// </div> */}
export default Ceo
